<template>
    <div>
        <v-row>
          <v-col cols="6" sm="6">
            <p class="title-user-scr"> Nuevo Ticket</p>         
          </v-col>
          <v-col cols="6" sm="6">
            <v-btn color="primary" small fab dark  :to="{ name: 'mytickets' }" style="margin-inline-start:70%">
                  <v-icon>mdi-arrow-u-left-top</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        
      <v-form ref="formNewticket" >
         
        <v-text-field 
            v-model="subject" 
            placeholder="Asunto" 
            :rules="rules.subject"></v-text-field>
        <v-textarea  
          label="Mensaje"
          outlined
          v-model="message"  
          :rules="[rules.required]"
          name="message"
        ></v-textarea>
        <v-btn depressed color="#00aff2" dark @click="handleCreate()"> Enviar </v-btn>
      </v-form>
    </div>
    </template>
    
    <script>
    import userTicketService from '@/services/users/tickets';

    export default {
      data() {
        return {
         message: "",
         subject: "",
         rules: {
            required: (value) => !!value || 'Requerido.',
                    
            subject: [
            (v) => !!v || 'Asunto es requerido',
            (v) => (v && v.length <= 255) || 'Asunto debe ser menor a 255 caracteres ',
            (v) => (v && v.length >= 2 )|| 'Asunto debe ser mayor a 2 caracteres ',
            ],
      },
        };
      },
      methods: {
        async handleCreate() {
            if (this.$refs.formNewticket.validate()) {
                 await userTicketService.create( this.subject, this.message ).then((response) => {
                   console.log(response.data);
                   this.$router.push({ name: 'mytickets' });
                    })
                }
            }
        }
    };
    </script>
    
    <style lang="scss" scoped>
    @import './src/assets/style.scss';
    </style>